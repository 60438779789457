import "./src/assets/css/style.css";

const updateFavicon = isDark => {
  const faviconElement = document.querySelector('link[rel="icon"]');
  faviconElement.href = isDark ? "favicon-dark.ico" : "favicon.ico";
};

export const onRouteUpdate = () => {
  const darkMediaQuery =
    typeof window !== "undefined" &&
    window.matchMedia("(prefers-color-scheme: dark)");
  darkMediaQuery.addListener(e => {
    updateFavicon(e.matches);
  });

  if (darkMediaQuery.matches) updateFavicon(true);
};
